<template>
    <el-dialog title="添加补单数据" :close-on-click-modal="false" width="700px" top="18vh"
               :visible.sync="dialogVisible" @close="closeDialog" append-to-body>
        <el-row :gutter="15">
            <el-form :model="formData" ref="formData" label-width="110px" :rules="rules" :size="$store.getters.size">
                <el-col :span="12">
                    <el-form-item label="所属仓库" prop="warehouseId">
                        <el-select filterable placeholder="选择仓库" v-model="formData.warehouseId">
                            <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="司机" prop="createBy">
                        <el-select v-model="formData.createBy" placeholder="请选择司机" filterable @change="driverChange">
                            <el-option v-for="(item, index) in driverOption" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="企业名称" prop="companyId" >
                        <el-select v-model="formData.companyId" filterable style="width: 100%;" placeholder="输入企业名称搜索"
                                   :remote="true" :remote-method="remoteMethod" :loading="remoteLoading">
                            <el-option v-for="(item, index) in companyOption" :label="item.companyName" :value="item.id" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="订单与地址" prop="orderId">
                        <el-select v-model="formData.orderId" filterable style="width: 100%;" placeholder="请选择下单与地址">
                            <el-option v-for="(item, index) in companyOrderOption" :label="item.address" :value="item.orderId" :key="index">
                                <p style="max-width:400px;overflow: hidden;">{{item.address}}
                                    <span style="float: right;position: absolute; right: 20px;">{{item.createTime}}</span>
                                </p>

                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="送货日期" prop="sendDate">
                        <el-date-picker
                                type="date"
                                placeholder="请选择送货日期"
                                v-model="formData.sendDate"
                                value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="基础运费" prop="basicFreight">
                        <el-input-number v-model="formData.basicFreight" :precision="2" :min="0" class="win_f"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="平台补贴" prop="serviceFreight">
                        <el-input-number v-model="formData.serviceFreight" :precision="2" :min="0" class="win_f"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="特殊运费" prop="specialFreight">
                        <el-input-number v-model="formData.specialFreight" :precision="2" :min="0" class="win_f"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总运费" prop="freight">
                        <el-input-number disabled v-model="formData.freight" :precision="2" :min="0" class="win_f"></el-input-number>
                    </el-form-item>
                </el-col>


                <el-col :span="12">
                    <el-form-item label="留框数量" prop="leaveBasketNum">
                        <el-input-number v-model="formData.leaveBasketNum" :step="1" step-strictly class="win_f" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="回框数量" prop="returnBasketNum">
                        <el-input-number v-model="formData.returnBasketNum" :step="1" step-strictly class="win_f" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="留豆腐板" prop="leavePlankNum">
                        <el-input-number v-model="formData.leavePlankNum" :step="1" step-strictly class="win_f" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="回豆腐板" prop="returnPlankNum">
                        <el-input-number v-model="formData.returnPlankNum" :step="1" step-strictly class="win_f" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="saveData" :loading="formLoading">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {searchCompany, findCompanyAddress, findCompanyOrder, findAddrFreight} from "@/api/system/company"
    import {getAllDriver} from '@/api/system/driver'
    import { saveDeliverySupplement } from '@/api/delivery/supplement'

    export default {
        name: "SaveDeliverySupplement",
        data(){
            return {
                dialogVisible: false,
                warehouseOption: this.$store.getters.warehouse,
                companyOption: [],
                addrOption: [],
                driverOption: [],
                companyOrderOption: [],
                remoteLoading: false,
                formLoading: false,
                formData: {
                    sendDate: undefined,
                    createBy: undefined,
                    driverName: undefined,
                    leaveBasketNum: 0,
                    returnBasketNum: 0,
                    leavePlankNum: 0,
                    returnPlankNum: 0,
                    companyId: undefined,
                    companyName: undefined,
                    companyAbbr: undefined,
                    addrId: undefined,
                    address: undefined,
                    warehouseId: undefined,
                    orderId: undefined,
                    freight: 0,
                    basicFreight: 0,
                    serviceFreight: 0,
                    specialFreight: 0
                },
                rules: {
                    warehouseId: [
                        { required: true, message: "请选择所属仓库", trigger: "blur" }
                    ],
                    companyId: [
                        { required: true, message: "请选择送货企业", trigger: "blur" }
                    ],
                    orderId: [
                        { required: true, message: "请选择订单与地址", trigger: "blur" }
                    ],
                    freight: [
                        { required: true, message: "请输入运费", trigger: "blur" }
                    ],
                    basicFreight: [
                        { required: true, message: "请输入基础运费", trigger: "blur" }
                    ],
                    serviceFreight: [
                        { required: true, message: "请输入平台补贴费用", trigger: "blur" }
                    ],
                    specialFreight: [
                        { required: true, message: "请输入特殊运费", trigger: "blur" }
                    ],
                    createBy: [
                        { required: true, message: "请选择送货司机", trigger: "blur" }
                    ],
                    sendDate: [
                        { required: true, message: "请选择送货日期", trigger: "blur" }
                    ]
                }
            }
        },
        computed: {
            totalFreightDo(){
                let {basicFreight, serviceFreight, specialFreight}  = this.formData
                return {
                    basicFreight, serviceFreight ,specialFreight
                }
            }
        },
        methods: {
            sumFreight(data){
                this.formData.freight = data.basicFreight + data.serviceFreight + data.specialFreight
            },
            show(){
                this.dialogVisible = true
            },
            closeDialog(){
                this.dialogVisible = false
                this.companyOption = []
                this.addrOption = []
                this.driverOption = []
                this.companyOrderOption = []
                this.formData = {
                    sendDate: undefined,
                    createBy: undefined,
                    driverName: undefined,
                    leaveBasketNum: 0,
                    returnBasketNum: 0,
                    leavePlankNum: 0,
                    returnPlankNum: 0,
                    companyId: undefined,
                    companyName: undefined,
                    addrId: undefined,
                    address: undefined,
                    warehouseId: undefined,
                    companyAbbr: undefined,
                    freight: 25
                }
            },
            remoteMethod(data){
                if(data && this.formData.warehouseId){
                    let param = {warId: this.formData.warehouseId, companyName: data}
                    searchCompany(param).then(({code, data}) => {
                        if(code === 0){
                            this.companyOption = data
                        }
                    })
                }
            },
            driverChange(val) {
                for(let i = 0; i < this.driverOption.length; i++){
                    if(this.driverOption[i].guid === val){
                        this.formData.driverName = this.driverOption[i].driverName
                        break
                    }
                }
            },
            saveData(){
                this.$refs['formData'].validate((valid) => {
                    if(valid){
                        this.formLoading = true
                        saveDeliverySupplement(this.formData).then(({code}) => {
                            this.formLoading = false
                            if(code === 0){
                                this.$message.success("添加成功")
                                this.closeDialog()
                                this.$parent.getTableData()
                            }
                        })
                    }
                })
            }
        },
        watch: {
            totalFreightDo: function (val) {
                // console.log(val)
                this.sumFreight(val)
            },
            'formData.warehouseId': function(val) {
                this.formData.companyId = undefined
                this.formData.companyName = undefined
                this.formData.companyAbbr = undefined
                this.formData.createBy = undefined
                this.formData.driverName = undefined
                if(val){
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.driverOption = data;
                        }
                    })
                }else{
                    this.driverOption = []
                }
            },
            'formData.companyId': function (val) {
                this.formData.addrId = undefined
                this.formData.address = undefined
                if(val){
                    for(let i = 0; i< this.companyOption.length; i++){
                        if(this.companyOption[i].id === val){
                            this.formData.companyName = this.companyOption[i].companyName
                            this.formData.companyAbbr = this.companyOption[i].companyAbbr
                            break
                        }
                    }
                    findCompanyOrder({companyId: val, warId: this.formData.warehouseId}).then(({code, data}) => {
                        if(code === 0){
                            this.companyOrderOption = data
                        }
                    })
                }
            },
            'formData.orderId': function (val) {
                if(val){
                    // console.log(val)
                    let companyOrder = undefined
                    for(let i = 0; i< this.companyOrderOption.length; i++){
                        if(this.companyOrderOption[i].orderId === val){
                            companyOrder = this.companyOrderOption[i]
                            this.formData.addrId = companyOrder.addrId
                            this.formData.address = companyOrder.address
                            let date = new Date(companyOrder.createTime)
                            date = new Date (date.getTime() - 24 * 60 * 60 * 1000)
                            this.formData.sendDate = date.getFullYear() + '-' +
                                (date.getMonth()<9 ? '0' + (date.getMonth()+1) : date.getMonth()+1) + '-' +
                                (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate())
                            break
                        }
                    }
                    if(companyOrder){
                        findAddrFreight(companyOrder).then(({code, data}) => {
                            if(code === 0){
                                this.formData.basicFreight = data.basicsFreight || 0
                                this.formData.serviceFreight = data.serviceFreight || 0
                                this.formData.specialFreight = data.specialFreight || 0
                            }
                        })
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .win_f {width: 94%;}
</style>
