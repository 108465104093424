<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.companyId" clearable
                           filterable remote reserve-keyword
                           placeholder="企业名称"
                           :remote-method="remoteMethod"
                           :loading="searchCompanyLoading">
                    <el-option v-for="item in companyOption" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.driverId" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        v-model="queryParams.localDates"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch" :loading="loading">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="15" class="mb20">
            <el-col :span="24">
                <el-button
                        type="primary"
                        icon="el-icon-plus"
                        :size="$store.getters.size"
                        v-if="$app.haveRole('delivery:supplement:add')"
                        @click="$refs['saveDelivery'].show()"
                >添加补单</el-button>
            </el-col>
        </el-row>

        <el-table :data="tableData" :size="$store.getters.size" v-loading="loading" ref="table"
                  @selection-change="handleSelectionChange" row-key="guid">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="补单人名称" prop="realName" width="120"></el-table-column>
            <el-table-column label="补单日期" prop="createTime" width="180"></el-table-column>

            <el-table-column label="配送司机" prop="driverName" width="100"></el-table-column>
            <el-table-column label="配送日期" prop="sendTime" width="120"></el-table-column>
            <el-table-column label="企业简称" prop="companyAbbr" width="80"></el-table-column>
            <el-table-column label="企业名称" prop="companyName" width="180"></el-table-column>
            <el-table-column label="收货地址" prop="address" width="350" ></el-table-column>
            <el-table-column label="运费" prop="freight" width="88"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-delete" @click="removeSupplement(scope.row)"
                               v-if="$app.haveRole('delivery:supplement:remove')">删除补单</el-button>
                </template>
            </el-table-column>
        </el-table>

        <page-item :total="total" ref="pageItem"/>
        <save-delivery-supplement ref="saveDelivery" />
    </div>
</template>

<script>
    import {searchCompany} from '@/api/system/company'
    import {getAllDriver} from '@/api/system/driver'
    import { getPageList, removeSupplement } from '@/api/delivery/supplement'
    import PageItem from '@/components/PageItem/'
    import SaveDeliverySupplement from './save_delivery_supplement'

    export default {
        name: "DeliverSupplement",
        components: { PageItem, SaveDeliverySupplement },
        data(){
            return {
                total: 0,
                loading: false,
                queryParams: {
                    warId: undefined,
                    localDates: undefined,
                    driverId: undefined,
                    companyId: undefined
                },
                tableData: [],
                multipleSelection: [],
                warehouseOption: this.$store.getters.warehouse,
                drivers: [],
                companyOption: [],
                searchCompanyLoading: false,
            }
        },
        methods: {
            getTableData(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getPageList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            removeSupplement(row){
                this.$confirm('你确定要删除此补单数据?', '提示', {type: "warning"})
                .then(() => {
                    removeSupplement(row.deliveryId).then(({code}) => {
                        if(code === 0){
                            this.$message.success("删除成功")
                            this.getTableData()
                        }
                    })
                })
            },
            remoteMethod(query){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库")
                    return false
                }
                if(!query){
                    this.companyOption = []
                    return
                }
                let param = {warId: this.queryParams.warId, companyName: query}
                searchCompany(param).then(({code, data}) => {
                    if(code === 0){
                        this.companyOption = data
                    }
                })
            }
        },
        watch: {
            'queryParams.warId': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
