import request from '@/utils/request'

export function getPageList(params) {
    return request({
        url: '/delivery/supplement/getPageList',
        method: 'GET',
        params: params
    })
}

/** 保存补单数据 */
export function saveDeliverySupplement(param) {
    return request({
        url: '/delivery/supplement/saveDeliverySupplement',
        method: 'POST',
        data: param
    })
}

/** 删除补单数据 */
export function removeSupplement(deliveryId) {
    return request({
        url: '/delivery/supplement/remove/'+deliveryId,
        method: 'DELETE'
    })
}
